<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card elevation="2">
          <v-breadcrumbs :items="items" divider="/"></v-breadcrumbs>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <base-material-card
          :title="$vuetify.lang.t('$vuetify.basicData')"
          icon="mdi-apple-finder"
        >
          <v-row>
            <v-col cols="12" lg="6" md="6" sm="6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.search')"
                v-model="search"
                append-icon="mdi-magnify"
              />
            </v-col>

            <v-col cols="12" lg="6" md="6" sm="6">
              <v-select
                :label="$vuetify.lang.t('$vuetify.selecttype')"
                :items="category"
                :item-text="(item) => item.name"
                return-object
                clearable
                @change="(item) => handleCategory(item)"
              />
            </v-col>

            <v-spacer />

            <v-col
              cols="12"
              class="d-flex justify-end align-center"
              v-if="$vuetify.breakpoint.lgAndUp"
            >
              <v-btn color="green" @click="categoryDialog = true">
                <v-icon left>mdi-plus</v-icon>
                New Category
              </v-btn>

              <v-btn color="green" elevation="2" @click="handleDialog">
                <v-icon left>mdi-account-plus</v-icon>
                {{ $vuetify.lang.t("$vuetify.add") }}
              </v-btn>

              <v-btn color="green" class="ma-1" elevation="2">
                <v-icon left>mdi-file-import</v-icon>
                {{ $vuetify.lang.t("$vuetify.batchUpload") }}
              </v-btn>
            </v-col>

            <v-col cols="12" class="d-flex justify-end align-center" v-else>
              <v-btn color="green" @click="categoryDialog = true">
                <v-icon>mdi-plus</v-icon>
              </v-btn>

              <v-btn color="green" elevation="2" @click="handleDialog">
                <v-icon>mdi-account-plus</v-icon>
              </v-btn>

              <v-btn color="green" class="ma-1" elevation="2">
                <v-icon>mdi-file-import</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <GeneralTable
                :head="headers"
                :data="datas"
                :search="search"
                :dialog.sync="dialog"
                v-on:edititem="handleDialogEdit"
                v-on:deleteitem="handleDeleteDialog"
              />
            </v-col>
          </v-row>
        </base-material-card>

        <BasicDataCRUD
          :dialog.sync="dialog"
          :myObj="obj"
          :category.sync="category"
          @handledata="handlebasicData"
        />

        <DialogDelete
          :dialogDelete.sync="dialogDelete"
          :deleteObj="deleteObj"
          @handleDelete="handleDeleteConfirm"
        />

        <BasicDataCategory
          :categoryDialog.sync="categoryDialog"
          :category.sync="category"
          @handleDone="getData()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//TODO missing batch upload

import {
  baseDataList,
  dataTypesList,
  baseDataDel,
  baseDataUpsert,
} from "@/api/system";

const newDelete = () => {
  return {
    id: "",
    type_code: "",
    type_name: "",
    code: "",
    name: "",
    note: "",
  };
};

const newObj = () => {
  return {
    id: "",
    type_code: "",
    type_name: "",
    code: "",
    name: "",
    note: "",
  };
};

const newSearch = () => {
  return {
    key: "",
    skip: 0,
    limit: 500000,
    type_code: "",
  };
};

export default {
  name: "basicData",
  components: {
    GeneralTable: () => import("@/components/tableImg"),
    BasicDataCRUD: () => import("@/components/system/basicDataCRUD"),
    DialogDelete: () => import("@/components/deleteDialog"),
    BasicDataCategory: () => import("@/components/system/basicDataCategory"),
  },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      categoryDialog: false,
      search: "",
      obj: newObj(),
      deleteObj: newDelete(),
      searchForm: newSearch(),
      datas: [],
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.itemNo"),
          value: "itemNo",
          sortable: false,
          align: "center",
          width: "80",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.type"),
          value: "type_name",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.name"),
          value: "name",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.code"),
          value: "code",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.note"),
          value: "note",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.opt"),
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],
      items: [
        { text: this.$vuetify.lang.t("$vuetify.home") },
        { text: this.$vuetify.lang.t("$vuetify.sysMgr") },
        { text: this.$vuetify.lang.t("$vuetify.basicData") },
      ],
      category: [],
      selectedItem: [
        {
          text: this.$vuetify.lang.t("$vuetify.categoryListAll"),
          value: "all",
        },
      ],
    };
  },
  methods: {
    getData() {
      this.category = [];
      this.datas = [];

      baseDataList(this.searchForm)
        .then((data) => {
          if (data.items != null) {
            this.datas = [...data.items];

            this.datas.forEach((p, k) => {
              p.itemNo = k + 1;
            });
          } else {
            this.datas = [];
            this.$toast.info("No Data Found");
          }
        })
        .catch((err) => console.log("BaseData Error", err));

      dataTypesList()
        .then((res) => {
          this.category = [...res.items];
        })
        .catch((err) => console.log("Base Type Error", err));
    },
    handlebasicData(obj) {
      this.dialog = false;

      baseDataUpsert(obj)
        .then(() => {
          this.getData();
        })
        .catch((err) => console.log("New Basic Data: ", err));
    },
    handleDialog() {
      this.dialog = true;
      this.obj = newObj();
    },
    handleDialogEdit(obj) {
      if (this.permissionCheck("modify")) {
        this.dialog = true;
        this.obj = obj;
      }
    },
    handleDeleteDialog(obj) {
      this.dialogDelete = true;
      this.deleteObj = obj;
    },
    handleDeleteConfirm(obj) {
      this.dialogDelete = false;

      baseDataDel(obj.id)
        .then(() => {
          this.datas.splice(this.datas.indexOf(obj), 1);
        })
        .catch((err) => console.log("Basic Data Delete Error", err));
    },
    handleCategory(item) {
      if (item != null) {
        this.searchForm.type_code = item.tree_code;
      } else {
        this.searchForm = newSearch();
      }

      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
};
</script>